import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const viewContact = '/server2/theme_contact';

const ContactSet = () => {

const { contact, GetContact } = GlobalContext();
const [currCont, setCurrCont] = useState([]);

const updateContactTh = async() => {
const whatsappNum = $('#cSetWhatNum').val();
const teleUsername = $('#cSetTeleUsr').val();
const instaUsername = $('#cSetInstaUsr').val();
const mailid = $('#cSetsupMail').val();
const contact1 = $('#cSetContNum1').val();
const contact2 = $('#cSetContNum2').val();

try {
const sendData = await axios.post('/server2/update_theme_contact', {
whatsappNum: whatsappNum,
teleUsername: teleUsername,
instaUsername: instaUsername,
mailid: mailid,
contact1: contact1,
contact2: contact2
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('contact changes saved successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetContact(viewContact);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend update_contact_settings error : ' + error);
}
}

useEffect(() => {
if(contact.length > 0){
setCurrCont(contact);
}
},[contact]);

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>

<div className='profilePage'>
<div className='headerProfile'><span>contact settings</span></div>

<div className='contactSetDiv'>
<div className='contactSetView'>
<div className='contactSetInput'>
<label>whatsapp number</label>
<input defaultValue={currCont.length > 0 ? currCont[0].whatsappNum : null} type='number' name='cSetWhatNum' id='cSetWhatNum' placeholder='Enter whatsapp number...' />
</div>
<div className='contactSetInput inSM'>
<label>telegram username</label>
<input defaultValue={currCont.length > 0 ? currCont[0].teleUsername : null} type='text' name='cSetTeleUsr' id='cSetTeleUsr' placeholder='Example : example_365...' />
</div>
<div className='contactSetInput inSM'>
<label>instagram username</label>
<input defaultValue={currCont.length > 0 ? currCont[0].instaUsername : null} type='text' name='cSetInstaUsr' id='cSetInstaUsr' placeholder='Example : example_365...' />
</div>
<div className='contactSetInput'>
<label>support mailid</label>
<input defaultValue={currCont.length > 0 ? currCont[0].mailid : null} type='email' name='cSetsupMail' id='cSetsupMail' placeholder='Enter mail id...' />
</div>
<div className='contactSetInput'>
<label>contact number 1</label>
<input defaultValue={currCont.length > 0 ? currCont[0].contact1 : null} type='number' name='cSetContNum1' id='cSetContNum1' placeholder='Enter contact number...' />
</div>
<div className='contactSetInput'>
<label>contact number 2</label>
<input defaultValue={currCont.length > 0 ? currCont[0].contact2 : null} type='number' name='cSetContNum2' id='cSetContNum2' placeholder='Enter contact number...' />
</div>
<div className='contactSetSubmit'>
<button onClick={updateContactTh}>update changes</button>
</div>
</div>
</div>

</div>

</div>
</div>

</div>
</div>
</>
)
}

export default ContactSet;
