import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const onlTransApi = '/server2/online_transation';
const userActive_api = '/server2/active-user';

const DepoRequest = () => {

const { allUsers, coupon, tranData, userActive, GetTranData, activeUser } = GlobalContext();
const [ currPayReq, setCurrPayReq] = useState([]);
const [usrCoupon, setUsrCoupon] = useState([]);
//console.log(usrCoupon);

const approveDeposit = async(id,type) => {
const findCurrReq = currPayReq.length > 0 && currPayReq.filter((e) => e.id === id);
var tranId = id;
var tranNum = $('#tranNum_'+id).val();
var receiver = findCurrReq.length > 0 && findCurrReq[0].receiver;
var sender = findCurrReq.length > 0 && findCurrReq[0].sender;
var couponVal = usrCoupon.length > 0 && usrCoupon.filter((e) => e.user === sender);
var matchCou = couponVal.length > 0 ? couponVal[0].couBonus : 0;
var depositAmt = findCurrReq.length > 0 && findCurrReq[0].depositAmt + matchCou;
var depositType = type;
var couponStatus = matchCou > 0 ? 'applied' : 'not_applied';
//console.log(receiver +' -- '+ sender);
//console.log(matchCou);
//console.log(depositAmt);
//console.log(couponStatus);
//return false;

if(tranNum === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('transation number required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#payReqLoad_'+id).css({display: 'flex'});
try {
const sendData = await axios.post('/server2/deposit_online_clear', {
tranId : tranId,
tranNum : tranNum,
receiver : receiver,
sender : sender,
depositAmt : depositAmt,
depositType : depositType,
couponStatus : couponStatus
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('deposit successfully cleared');
setTimeout(() => {
$('#msgAlert').hide();
$('#payReqLoad_'+id).hide();
$('#tranNum_'+id).val('');
GetTranData(onlTransApi);
activeUser(userActive_api);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
$('#payReqLoad_'+id).hide();
$('#tranNum_'+id).val('');
},1000);  
}
} catch (error) {
console.log('backend deposit_online error : ' + error);
}
}
}

useEffect(() => {
if(tranData.length > 0 && userActive.length > 0){
setCurrPayReq(tranData.filter((e) => e.receiver === userActive[0].customer && e.paymentStatus === 'pending' && e.paymentMethod === 'deposit'));
}
},[tranData, userActive]);

useEffect(() => {
if(coupon.length > 0 && allUsers.length > 0){
var couponUsr = [];
for(let i = 0; i < allUsers.length; i++){
for(let j = 0; j < coupon.length; j++){
if(allUsers[i].applycode === coupon[j].couponCode && allUsers[i].applyStatus === null){
var data = {
user: allUsers[i].customer,
couName: coupon[j].couponName,
couCode: coupon[j].couponCode,
couBonus: coupon[j].couponDis
}
const findMatch = couponUsr.findIndex((o) => o.user === allUsers[i].customer);
if(findMatch === -1){
couponUsr.push(data);
}
}
}
}
;
setUsrCoupon(couponUsr);
}
},[allUsers, coupon]);

useEffect(() => {
GetTranData(onlTransApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
{
currPayReq.length > 0 ? currPayReq.map((data, index) => {
return (
<div className='payReqRowWrap' key={index}>

<div className='payReqLoad' id={'payReqLoad_'+data.id}>
<div>
<img src='/images/paper-plane.png' alt='paper-plane' />
<span>deposit request sending</span>
</div>
</div>

<div className='paymentTitle'>
<span>{data.paymentType} deposit by {data.sender}</span>
</div>

<div className='payReqRow'>

<div className='col'>
<div className='viewPicDiv'>
<img src={data.path} alt='viewPyPic' />
</div>
</div>

<div className='col'>
{
data.paymentType === 'upi' &&
<div className='payReqData'>
<div className='col_1'>
<span>upi account name :</span>
</div>
<div className='col_1'>
<span>{data.upiAcName}</span>
</div>
</div>
}

{
data.paymentType === 'upi' &&
<div className='payReqData'>
<div className='col_1'>
<span>upi account id :</span>
</div>
<div className='col_1'>
<span>{data.upiAcId}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>bank name :</span>
</div>
<div className='col_1'>
<span>{data.bankName}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>holder name :</span>
</div>
<div className='col_1'>
<span>{data.holderName}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>account no. :</span>
</div>
<div className='col_1'>
<span>{data.bankAcNo}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>ifsc code :</span>
</div>
<div className='col_1'>
<span>{data.bankIfsc}</span>
</div>
</div>
}

<div className='payReqData'>
<div className='col_1'>
<span>sender :</span>
</div>
<div className='col_1'>
<span>{data.sender}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>sender role :</span>
</div>
<div className='col_1'>
<span>{data.sender_role}</span>
</div>
</div>

{
usrCoupon.length > 0 && usrCoupon.filter((e) => e.user === data.sender).map((data2, index2) => {
return(
<div className='payReqData' key={index2}>
<div className='col_1'>
<span>coupon applied :</span>
</div>
<div className='col_1 colGrid'>
<div className='colGrid_col'>
<span>name : </span>
<span>{data2.couName}</span>
</div>
<div className='colGrid_col'>
<span>code : </span>
<span>{data2.couCode}</span>
</div>
<div className='colGrid_col'>
<span>bonus : </span>
<span>{parseFloat(data2.couBonus.toFixed(2)).toLocaleString()}</span>
</div>
<div className='colGrid_col'>
<span>pay amt. : </span>
<span>{parseFloat((data.depositAmt + data2.couBonus).toFixed(2)).toLocaleString()}</span>
</div>
</div>
</div>
)
})
}

<div className='payReqData'>
<div className='col_1'>
<span>deposit amount :</span>
</div>
<div className='col_1'>
<span>{parseFloat(data.depositAmt.toFixed(2)).toLocaleString()}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>payment type :</span>
</div>
<div className='col_1'>
<span>{data.paymentType}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>payment status :</span>
</div>
<div className='col_1'>
<span>{data.paymentStatus}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>payment date :</span>
</div>
<div className='col_1'>
<span>{data.dateTime}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>enter transation detail :</span>
</div>
<div className='col_1'>
<input type='text' className='tranNum' id={'tranNum_'+data.id} placeholder='enter transation number' />
</div>
</div>

<div className='payReqData payReqDataBut'>
<div className='col_1'>
<button onClick={() => approveDeposit(data.id,'approve')}>approve</button>
</div>
<div className='col_1'>
<button onClick={() => approveDeposit(data.id,'reject')}>reject</button>
</div>
</div>

</div>
</div>
</div>
)
})
:
<div className='noRecDiv'>
<div className='gridDiv gridDivNoRec'>
<img src='/images/card.png' alt='card' />
<span>you have no any deposit request</span>
</div>
</div>
}
</>
)
}

export default DepoRequest;
