import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const viewSliders = '/server2/theme_sliders';

const SliderSet = () => {

const { sliders, GetSliders } = GlobalContext();
const [themeSliders, setThemeSliders] = useState(null);
const [currThemeSliders, setCurrThemeSliders] = useState([]);

const cancelThemeSliUp = () => {
setThemeSliders(null);
}

const uplodThemeSliUp = async() => {
const formData = new FormData();
$('.uploadDivSlider button').attr('disabled', 'disabled');
if(themeSliders === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('first select to upload image');
setTimeout(() => {
$('#msgAlert').hide();
$('.uploadDivSlider button').removeAttr('disabled');
},2000);
return false;
}
else{
try {
for(let i = 0; i < themeSliders.length; i++){
formData.append('themeSlider', themeSliders[i]);
}

const sendData = await axios.post('/server2/uploadTheme_slider', formData, {
headers : {"Content-Type": "multipart/form-data"}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('image uploaded successfully');
setTimeout(() => {
$('#msgAlert').hide();
setThemeSliders(null);
GetSliders(viewSliders);
$('.uploadDivSlider button').removeAttr('disabled');
},2000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
$('.uploadDivSlider button').removeAttr('disabled');
},2000);  
}
} catch (error) {
console.log('backned upload multi slider image error : ' + error);
}
}
}

const removeThSlider = async(id,path) => {
//alert(id +' -- '+ path.replace('/server2', 'public'));
const setPath = path.replace('/server2', 'public');

try {
const sendData = await axios.post('/server2/remove_sliders', {
id: id,
setPath: setPath
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('image removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetSliders(viewSliders);
},2000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},2000);    
}
} catch (error) {
console.log('backend remove_theme_slider error : ' + error); 
}
}

useEffect(() => {
if(themeSliders !== null){
for(let i = 0; i < themeSliders.length; i++){
//console.log(themeSliders[i]);
document.getElementById('groupImgDiv').innerHTML += `<div class='groupImgB'><img src=${URL.createObjectURL(themeSliders[i])} alt='group_imgS' /></div>`;
}
}
},[themeSliders]);

useEffect(() => {
if(sliders.length > 0){
setCurrThemeSliders(sliders);
}
else{
setCurrThemeSliders([]);
}
},[sliders]);

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>

<div className='profilePage'>
<div className='headerProfile'><span>slider settings</span></div>

<div className='themeSetDiv'>
<div className='addImageBox'>
{
themeSliders === null ?
<div className="qrHolderFile">
<label>
<i className="fa fa-plus"></i>
<input onChange={(e) => setThemeSliders(e.target.files)} type="file" name="sliderImgTh" id="sliderImgTh" accept="image/*" multiple />
click here to upload slider images
</label>
</div>
:
<div>
<div className='uploadDivSlider'>
<button onClick={uplodThemeSliUp}>upload</button>
<button onClick={cancelThemeSliUp}>cancel</button>
</div>

<div className='groupImgDivWrap'>
<div className='groupImgDiv' id='groupImgDiv'></div>
</div>
</div>
}
</div>
</div>
</div>

<div className='profilePage marTop25'>
<div className='headerProfile'><span>available sliders</span></div>

<div className='viewSliderWrap'>
<div className='viewSliderDiv'>
<div className='viewSliderRow'>
{
currThemeSliders.length > 0 ? currThemeSliders.map((data, index) => {
return (
<div className='viewSliderBox' key={index}>
<img src={data.pathImage} alt='slider-img' />
<div className='viewSlideCon'>
<div className='viewSlide'>
<span>
<a href={data.pathImage} target='blank'><i className='fa fa-eye' /></a>
<i className='fa fa-trash' onClick={() => removeThSlider(data.id,data.pathImage)} />
</span>
<span>created date : {data.createDate}</span>
</div>
</div>
</div>
)
})
:
<div className='noreSlide'>
<img src='/images/cloud.png' alt='cloud' />
<span>oop's ! you have no any slider image</span>
</div>
}
</div>
</div>
</div>
</div>

</div>
</div>

</div>
</div>
</>
)
}

export default SliderSet;
