import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const Login = () => {

const [checked, setChecked] = useState(false);
const { usrIp } = GlobalContext();
//console.log(usrIp);

const handleLoginCheck = () => {
setChecked(!checked);
var x = document.getElementById('password');
if(checked === false){
if(x.type === 'password'){
x.type = 'text';
}
}else if(checked === true){
if(x.type === 'text'){
x.type = 'password';
}
}
}

const loginUser = async() => {
const customer = $('#username').val();
const password = $('#password').val();
const userIp = usrIp.length !== 0 ? usrIp.ip : null;
const city = usrIp.length !== 0 ? usrIp.city : null;
const region = usrIp.length !== 0 ? usrIp.region : null;
const country = usrIp.length !== 0 ? usrIp.country : null;
const org = usrIp.length !== 0 ? usrIp.org : null;
const postal = usrIp.length !== 0 ? usrIp.postal : null;
const timezone = usrIp.length !== 0 ? usrIp.timezone : null;

//console.log(userIp +' -- '+ city +' -- '+ region +' -- '+ country +' -- '+ org +' -- '+ postal +' -- '+ timezone);
//return false;

// alert(username +'-- '+ password);
$('#loginDesk').attr('disabled', 'disabled').css({background : 'grey'});

if(customer === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('username required');
setTimeout(function(){
$('#msgAlert').hide();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
return false;
}
else if(password === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('password required');
setTimeout(function(){
$('#msgAlert').hide();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/login', {
customer : customer,
password : password,
userIp : userIp,
city : city,
region : region,
country : country,
org : org,
postal : postal,
timezone : timezone
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('login success');
setTimeout(function(){
$('#msgAlert').hide();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
window.location.replace('/dashboard');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
}
} catch (error) {
console.log('backend login-api error : ' + error);
}
}
}

return (
<>
<div className='loginWrap'>
<div className='loginBox'>
<div className='loginContent'>

<div className="logo_login_wrap">
<div className='logo_BoxLog'>
<img src="/images/loginLogo.png" alt="logo-img" />
</div>
</div>

<div className='formWrap'>
<div className='formControl'>
<label>username *</label>
<input type='text' name='username' id='username' placeholder='username...' />
</div>
<div className='formControl'>
<label>password *</label>
<input type='password' name='password' id='password' placeholder='password...' />
</div>
<div className='formControl formControlFlex'>
<label style={{cursor: 'pointer'}}>
<input type='checkbox' checked={checked} onChange={handleLoginCheck} />
show password</label>
</div>
<div className='formControl'>
<button id='loginDesk' onClick={() => loginUser()}>login</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Login;
