import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const viewCoupon = '/server2/view_coupon';

const CouponStatus = () => {

const { userActive, GetCoupon } = GlobalContext();

const closeCouStatus = () => {
$('#statusCouModal').hide();
$('.couPass').val('');
$('#statusSelCou option').prop('selected', null);
}

const submitCouStatus = async() => {
const statusSelCou = $('#statusSelCou').val();
const id = $('#statusCouId').val();
const couPass = $('#couPass').val();
const currPass = userActive.length > 0 && userActive[0].password;

if(statusSelCou === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('status required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couPass === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('your password required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couPass !== currPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('your password no match');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/change_coupon_status', {
id: id,
status: statusSelCou
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('status changed successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#statusCouModal').hide();
$('.couPass').val('');
$('#statusSelCou option').prop('selected', null);
GetCoupon(viewCoupon);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend change_coupon_status error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='statusCouModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>change coupon status </span> <i className='fa fa-close right' onClick={() => closeCouStatus()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>curr. status</label></div>
<div>
<span id='statusCou'></span> 
</div>
</div>
<div className='formControls'>
<div><label>select status</label></div>
<div>
<select id='statusSelCou'>
<option value='null'>--select one status--</option>
<option value='active'>active</option>
<option value='closed'>closed</option>
</select>
</div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div>
<input className='clearCou' type='password' name='couPass' id='couPass' placeholder='admin password...' />
<input className='clearCou' type='hidden' id='statusCouId' />
</div>
</div>
<div className='formControlsSubt2'>
<button onClick={() => submitCouStatus()}>submit</button>
<button onClick={() => closeCouStatus()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default CouponStatus;
