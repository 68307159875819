import axios from 'axios';
import $ from 'jquery';

let countDataT = 0;
var usrDat = [];
var startChRe;

async function findUs() {
try {
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
if(resData.length > 0){
usrDat.push({customer: resData[0].customer});
getPayReq();
startChRe = setInterval(getPayReq, 5000);
}    
} catch (error) {
console.log('backend active_user_data error : ' + error);
}
}

async function getPayReq() {
if(usrDat.length > 0){
try {
const getData = await axios.get('/server2/online_transation');
const resData = await getData.data;
if(resData.length > 0){
const filterData = resData.filter((e) => e.receiver === usrDat[0].customer && e.paymentStatus === 'pending');
if(parseInt(filterData.length) > parseInt(countDataT)){
countDataT = filterData.length;
$('.payReqNot').html(countDataT);
//console.log('run new req');
playMusic();
}
else if(parseInt(countDataT) > parseInt(filterData.length)){
var currReq  = parseInt(countDataT) - parseInt(filterData.length === 0 ? 1 : filterData.length);
countDataT = currReq;
$('.payReqNot').html(countDataT);
//console.log('no new req');
}
}  
} catch (error) {
clearInterval(startChRe);
setTimeout(() => {
startChRe = setInterval(getPayReq, 5000);
},1000);
console.log('backend check_pay_req error : ' + error);
}
}
}

function playMusic() {
var audioClip = new Audio('/audio/audioNotice.mp3'); 
var playPromise = audioClip.play();
if (playPromise !== undefined) {
playPromise.then(_ => {
// Automatic playback started!
// Show playing UI.
})
.catch(error => {
// Auto-play was prevented
// Show paused UI.
});
}
}

export { findUs, countDataT };