import React from 'react';
import { useNavigate } from 'react-router-dom';

const Themes = () => {

const Navigate = useNavigate();

const sliderSetting = () => {
Navigate('/slider_setting');
}

const contactSetting = () => {
Navigate('/contact_setting'); 
}

const socialSetting = () => {
Navigate('/social_setting');    
}

const gameImgSetting = () => {
Navigate('/game_image_setting');      
}

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>

<div className='profilePage'>
<div className='headerProfile'><span>theme settings</span></div>
<div className='themeSetDiv'>
<div className='themeRow'>
<div className='col' onClick={sliderSetting}>
<img src='/images/slider_setting.png' alt='slider_setting' />
<span>slider setting</span>
</div>

<div className='col' onClick={contactSetting}>
<img src='/images/contact.png' alt='slider_setting' />
<span>contact setting</span>
</div>

<div className='col' onClick={socialSetting}>
<img src='/images/social-media.png' alt='slider_setting' />
<span>social setting</span>
</div>

<div className='col' onClick={gameImgSetting}>
<img src='/images/photo-editing.png' alt='slider_setting' />
<span>game image setting</span>
</div>
</div>
</div>
</div>

</div>
</div>

</div>
</div>
</>
)
}

export default Themes;
