import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const viewSocial = '/server2/theme_social';

const SocialSet = () => {

const { social, GetSocial } = GlobalContext();
const [currSocial, setCurrSocial] = useState([]);

const updateSocTh = async() => {
const instaLink = $('#socInstaLink').val();
const fbLink = $('#socFbLink').val();
const twitLink = $('#socTwiLink').val();
const teleLink = $('#socTelLink').val();
const whatsapp = $('#socWhat').val();

try {
const sendData = await axios.post('/server2/social_theme_update', {
instaLink: instaLink,
fbLink: fbLink,
twitLink: twitLink,
teleLink: teleLink,
whatsapp: whatsapp
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('update changes saved successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetSocial(viewSocial);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend social_setting_update error : ' + error);
}
}

useEffect(() => {
if(social.length > 0){
setCurrSocial(social);
}
},[social]);

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>

<div className='profilePage'>
<div className='headerProfile'><span>social settings</span></div>

<div className='contactSetDiv'>
<div className='contactSetView'>
<div className='contactSetInput'>
<label>instagram link</label>
<input defaultValue={currSocial.length > 0 ? currSocial[0].instaLink : null} type='text' name='socInstaLink' id='socInstaLink' placeholder='Enter instagram link...' />
</div>
<div className='contactSetInput'>
<label>facebook link</label>
<input defaultValue={currSocial.length > 0 ? currSocial[0].fbLink : null} type='text' name='socFbLink' id='socFbLink' placeholder='Enter facebook link...' />
</div>
<div className='contactSetInput'>
<label>twitter link</label>
<input defaultValue={currSocial.length > 0 ? currSocial[0].twitLink : null} type='text' name='socTwiLink' id='socTwiLink' placeholder='Enter twitter link...' />
</div>
<div className='contactSetInput'>
<label>telegram link</label>
<input defaultValue={currSocial.length > 0 ? currSocial[0].teleLink : null} type='text' name='socTelLink' id='socTelLink' placeholder='Enter telegram link...' />
</div>
<div className='contactSetInput'>
<label>whatsapp number</label>
<input defaultValue={currSocial.length > 0 ? currSocial[0].whatsapp : null} type='number' name='socWhat' id='socWhat' placeholder='Enter whatsapp number...' />
</div>
<div className='contactSetSubmit'>
<button onClick={updateSocTh}>update changes</button>
</div>
</div>
</div>

</div>

</div>
</div>

</div>
</div>
</>
)
}

export default SocialSet;
