import React from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { Link } from 'react-router-dom';

const userActive_api = '/server2/active-user';

const Header = () => {

const { userActive, activeUser } = GlobalContext();

const reloadBal = () => {
activeUser(`${userActive_api}`);
//console.log(userActive[0].total_balance +' -- '+ useExpo[0].exposure);
$('#roleBalUser').hide();
$('#balLoadDiv').css({display : 'inline-flex'});
setTimeout(function(){
$('#balLoadDiv').css({display : 'none'});
$('#roleBalUser').show();
},1000);
}

return (
<>
<div className='headerDiv'>
<div className='containers'>
<div className='rowsFlex'>
<div className='col'>
<div className="logo-wrap">
<Link to='/dashboard'> 
<img src='/images/24exchLogo.png' alt='24exchLogo' />
</Link>
</div>
</div>
<div className='col'>

{
userActive.length > 0 ? 
<div className='balanceDiv'>
<span className='gridMob'>
<span className='roleSpan'>
{
userActive[0].user_role === 'admin_role' ? 'white label' 
:
userActive[0].user_role === 'subadmin_role' ? 'subadmin'
:
userActive[0].user_role === 'super_role' ? 'super'
:
userActive[0].user_role === 'master_role' ? 'master'
:
userActive[0].user_role === 'agent_role' ? 'agent'
: 'null'
}
</span>
<span className='roleNameSpan'>{userActive[0].customer}</span>
</span>
<span className='gridMob'>
<span className='roleCurrSpan'>{userActive[0].currency}</span>
<span className='balLoad'>
<span className='balLoadDiv' id='balLoadDiv'>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>
</span>
<span className='roleBalSpan' id='roleBalUser'>{parseFloat(userActive[0].total_balance.toFixed(2)).toLocaleString()}</span>
</span>
<span className='roleReloadSpan' onClick={() => reloadBal()}><img src='/images/refresh-arrow.png' alt='refresh-arrow' /></span>
</span>
</div>
:
<div className='balanceDiv'>
<span className='roleNoUserSpan'>no user</span>
</div>
}

</div>
</div>
</div>
</div>
</>
)
}

export default Header;
