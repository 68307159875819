import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const viewCoupon = '/server2/view_coupon';

const RemoveCoupon = () => {

const { GetCoupon } = GlobalContext();

const closeRemoveCou = () => {
$('#removeCouModal').hide();
$('#removeCouId').val('');
}

const removeCou = async() => {
const id = $('#removeCouId').val();

try {
const sendData = await axios.post('/server2/remove_coupon', {id: id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('coupon removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#removeCouModal').hide();
$('#removeCouId').val('');
GetCoupon(viewCoupon);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend remove_coupon error : ' + error);
}

}

return (
<>
<div className='modalsSm' id='removeCouModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>remove coupon</span> <i className='fa fa-close right' onClick={() => closeRemoveCou()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/bin.png' alt='bin' />
<span>are you sure? you want to remove this coupon?</span>
<input type='hidden' name='removeCouId' className='clearRemoveCou' id='removeCouId' />
<button onClick={() => removeCou()}>yes</button>
<button onClick={() => closeRemoveCou()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default RemoveCoupon;
