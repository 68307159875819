import { useContext, useReducer, useEffect } from "react";
import { createContext } from "react";
import axios from "axios";
import Reducer from './Reducer';

const Context = createContext();

const userActive_api = '/server2/active-user';
const userExpo_api = '/server2/exposure-user';
const usersList_api = '/server2/users-list';
const userBanking_api = '/server2/user_bankingData';
const userActivity_api = '/server2/user_activity';
const totalBal_api = '/server2/users-total-bal';
const allUsers_api = '/server2/users-list-all';
const removeUsersList_api = '/server2/remove_usersList';
const unsettleBets_api = '/server2/unsettle_bets_List';
const settleBetsAll_api = '/server2/settle_betsAll';
const noticeApi = '/server2/notification_Data';
const betSettingApi = '/server2/betSettings_data';
const gameSetData = '/server2/game_settingsData';
const passHisApi = '/server2/password-history';
const CompetitionCricListApi = '/server2/competition-list/4';
const EventListApi = '/server2/events-list';
const upiDataApi = '/server2/upi_data';
const bankDataApi = '/server2/bank_data';
const onlTransApi = '/server2/online_transation';
const withDrawLimitApi = '/server2/withdrawLimit_data';
const viewCoupon = '/server2/view_coupon';
const viewSliders = '/server2/theme_sliders';
const viewContact = '/server2/theme_contact';
const viewSocial = '/server2/theme_social';
const viewGameImg = '/server2/theme_game_image';
const userIpApi = 'https://ipinfo.io/json';

const initialstate = {
userLoad : false,
userErr : false,
userActive : [],
expoLoad : false,
expoErr : false,
useExpo : [],
userList_load: false,
userList_err: false,
userList: [],
userBank_load: false,
userBank_err: false,
userBank: [],
usrAct_load: false,
usrAct_err: false,
usrAct: [],
isProfitLoad: false,
isProfitErr: false,
profitLossUser: [],
loadTotalBal: false,
errTotalBal: false,
total_bal_Users: [],
loadAllUser: false,
errAllUser: false,
allUsers: [],
loadRemoveusr: false,
errRemoveUsr: false,
removeUsersList: [],
loadUnsetBets: false,
errUnsetBets: false,
unsetBets: [],
isCricMarLoad: false,
isCricMarErr: false,
cricMarket: [],
isSoccMarLoad: false,
isSoccMarErr: false,
soccMarket: [],
isTennMarLoad: false,
isTennMarErr: false,
tennMarket: [],
loadNotice: false,
loadNoticeErr: false,
allNotice: [],
loadBetSet: false,
errBetSet: false,
betSet: [],
loadGameSet: false,
errGameSet: false,
gameSet: [],
loadPassHis: false,
errPassHis: false,
passHis: [],
loadCricComp: [],
errCricComp: [],
cricCompAll: [],
loadAllBetsSettle: false,
errAllBetsSettle: false,
allBetsSettle: [],
loadUpi: false,
errUpi: false,
upi_data: [],
loadBank: false,
errBank: false,
bank_data: [],
loadTran: false,
errTran: false,
tranData: [],
loadWithLimit: false,
errWithLimit: false,
withLimit: [],
loadCoupon: false,
errCoupon: false,
coupon: [],
loadSlider: false,
errSlider: false,
sliders: [],
loadCont: false,
errCont: false,
contact: [],
loadSocial: false,
errSocial: false,
social: [],
loadGameImg: false,
errGameImg: false,
gameImg: [],
loadUsrIp: false,
errUsrIp: false,
usrIp: []
}

const Provider = ({children}) => {

const [state, dispatch] = useReducer(Reducer, initialstate);

const activeUser = async(URL) => {
dispatch({type : 'ACTIVE_USER_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'ACTIVE_USER_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'ACTIVE_USER_ERR'});
console.log('backend context active user api error : ' + error);
}
}

const userExpo = async(URL) => {
dispatch({type : 'EXPO_USER_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'EXPO_USER_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'EXPO_USER_ERR'});
console.log('backend context exposure api error : ' + error);
}
}

const getUserLists = async(URL) => {
dispatch({type : 'USER_LIST_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'USER_LIST__SET', payload: resData});
}
} catch (error) {
dispatch({type : 'USER_LIST_ERR'});
console.log('backend context user-list api error : ' + error);
}
}

const userBanking = async(URL) => {
dispatch({type : 'USER_BANKING_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'USER_BANKING_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'USER_BANKING_ERR'});
console.log('backend context user-banking-data api error : ' + error);
}
}

const userActivity = async(URL) => {
dispatch({type : 'USER_ACTIVITY_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'USER_ACTIVITY_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'USER_ACTIVITY_ERR'});
console.log('backend context user-activity-log api error : ' + error);
}
}

const GetProfitLoss = async(customer,select_sport,start_date,end_date) => {
dispatch({type: 'PROFIT_LOSS_LOADING'});
try {
const sendData = await axios.post('/server2/profit_loss/', {
customer: customer,
select_sport: select_sport,
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
dispatch({type: 'PROFIT_LOSS_SET', payload: resData});
} catch (error) {
dispatch({type: 'PROFIT_LOSS_ERROR'}); 
}
}

const userBalTotal = async(URL) => {
dispatch({type : 'USER_TOTAL_BAL_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'USER_TOTAL_BAL_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'USER_TOTAL_BAL_ERR'});
console.log('backend context user-total-bal api error : ' + error);
}
}

const userAll = async(URL) => {
dispatch({type : 'USER_ALL_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'USER_ALL_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'USER_ALL_ERR'});
console.log('backend context user-all-list api error : ' + error);
}
}

const getRemoveUsrList = async(URL) => {
dispatch({type : 'REMOVE_USER_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'REMOVE_USER_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'REMOVE_USER_ERR'});
console.log('backend context remove-user api error : ' + error);
}
}

const getUnsetList = async(URL) => {
dispatch({type : 'UNSET_USER_LOADING'});
try {
const getData = await axios.get(URL);
const resData = await getData.data;
if(resData){
dispatch({type : 'UNSET_USER_SET', payload: resData});
}
} catch (error) {
dispatch({type : 'UNSET_USER_ERR'});
console.log('backend context unsettle-user api error : ' + error);
}
}

const GetCricketMarket = async(URL) => {
dispatch({type: 'CRICKET_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'CRICKET_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'CRICKET_MARKET_ERROR'}); 
}
}

const GetSoccerMarket = async(URL) => {
dispatch({type: 'SOCCER_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SOCCER_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'SOCCER_MARKET_ERROR'}); 
}
}

const GetTennisMarket = async(URL) => {
dispatch({type: 'TENNIS_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'TENNIS_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'TENNIS_MARKET_ERROR'}); 
}
}

const getNotice = async(URL) => {
dispatch({type: 'NOTICE_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'NOTICE_SET', payload: resData});
} catch (error) {
dispatch({type: 'NOTICE_ERROR'}); 
}
}

const getBetSet = async(URL) => {
dispatch({type: 'BET_SETTING_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'BET_SETTING_SET', payload: resData});
} catch (error) {
dispatch({type: 'BET_SETTING_ERROR'}); 
}
}

const getGameSet = async(URL) => {
dispatch({type: 'GAME_SETTING_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'GAME_SETTING_SET', payload: resData});
} catch (error) {
dispatch({type: 'GAME_SETTING_ERROR'}); 
}
}

const getPassHis = async(URL) => {
dispatch({type: 'PASS_HIS_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'PASS_HIS_SET', payload: resData});
} catch (error) {
dispatch({type: 'PASS_HIS_ERROR'}); 
}
}

const GetCompCric = async(URL) => {
var pushData = [];
var pushData2 = [];
dispatch({type: 'CRICKET_COMP_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
if(resData.length > 0){
resData.forEach( async(e) => {
var competitionId = e.competition.id;
var competitionName = e.competition.name;
try {
const getData = await axios.get(`${EventListApi}/${competitionId}`);
const resData2 = getData.data;
const findEvent = resData2.length > 0 && resData2.filter((f) => f.marketname === 'match odds' || f.marketname === 'match_odds');
if(findEvent.length > 0){
findEvent.forEach((g) => {
var data = {
comp_id : competitionId,
comp_name: competitionName,
event_id : g.event.id,
market_id : g.marketid,
event_name : g.event.name
}

const findInd = pushData.findIndex((h) => h.comp_id === competitionId && h.event_id === g.event.id);
if(findInd === -1){
pushData.push(data); 
}
});
}
} catch (error) {
console.log('frontend appcontext eventList_cric api error : ' + error);
}
const plusJson = pushData2.concat(pushData);
if(plusJson.length > 0){
dispatch({type: 'CRICKET_COMP_SET', payload: plusJson});
}
});
}
} catch (error) {
dispatch({type: 'CRICKET_COMP_ERROR'}); 
}
}

const GetAllSettleBets = async(URL) => {
dispatch({type: 'SETTLE_ALLBETS_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SETTLE_ALLBETS_SET', payload: resData});
} catch (error) {
dispatch({type: 'SETTLE_ALLBETS_ERROR'}); 
}
}

const GetUpiData = async(URL) => {
dispatch({type: 'UPI_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'UPI_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'UPI_DATA_ERROR'}); 
}
}

const GetBankData = async(URL) => {
dispatch({type: 'BANK_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'BANK_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'BANK_DATA_ERROR'}); 
}
}

const GetTranData = async(URL) => {
dispatch({type: 'TRANSATION_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'TRANSATION_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'TRANSATION_DATA_ERROR'}); 
}
}

const GetWithLimitData = async(URL) => {
dispatch({type: 'WITHDRAW_LIMIT_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'WITHDRAW_LIMIT_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'WITHDRAW_LIMIT_DATA_ERROR'}); 
}
}

const GetCoupon = async(URL) => {
dispatch({type: 'COUPON_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'COUPON_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'COUPON_DATA_ERROR'}); 
}
}

const GetSliders = async(URL) => {
dispatch({type: 'SLIDERS_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SLIDERS_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'SLIDERS_DATA_ERROR'}); 
}
}

const GetContact = async(URL) => {
dispatch({type: 'CONTACT_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'CONTACT_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'CONTACT_DATA_ERROR'}); 
}
}

const GetSocial = async(URL) => {
dispatch({type: 'SOCIAL_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SOCIAL_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'SOCIAL_DATA_ERROR'}); 
}
}

const GetGameImg = async(URL) => {
dispatch({type: 'GAMEIMG_DATA_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'GAMEIMG_DATA_SET', payload: resData});
} catch (error) {
dispatch({type: 'GAMEIMG_DATA_ERROR'}); 
}
}

const GetUsrIp = async(URL) => {
dispatch({type: 'USER_IP_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'USER_IP_SET', payload: resData});
} catch (error) {
dispatch({type: 'USER_IP_ERROR'}); 
}
}
    
useEffect(() => {
activeUser(userActive_api);
userExpo(userExpo_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
userActivity(userActivity_api);
userBalTotal(totalBal_api);
userAll(allUsers_api);
getRemoveUsrList(removeUsersList_api);
getUnsetList(unsettleBets_api);
getNotice(noticeApi);
getBetSet(betSettingApi);
getGameSet(gameSetData);
getPassHis(passHisApi);
GetCompCric(CompetitionCricListApi);
GetAllSettleBets(settleBetsAll_api);
GetUpiData(upiDataApi);
GetBankData(bankDataApi);
GetTranData(onlTransApi);
GetWithLimitData(withDrawLimitApi);
GetCoupon(viewCoupon);
GetSliders(viewSliders);
GetContact(viewContact);
GetSocial(viewSocial);
GetGameImg(viewGameImg);
GetUsrIp(userIpApi);
}, []);

return(
<Context.Provider value={{...state, GetCricketMarket, GetSoccerMarket, GetTennisMarket, getBetSet, getGameSet, getNotice, activeUser, userBalTotal, userBanking, userExpo, getUserLists, GetProfitLoss, userAll, getRemoveUsrList, getUnsetList, getPassHis, GetUpiData, GetBankData, GetTranData, GetWithLimitData, GetCoupon, GetSliders, GetContact, GetSocial, GetGameImg}}>{children}</Context.Provider>
)
}

const GlobalContext = () => {
return useContext(Context);
}

export { Context, Provider, GlobalContext};