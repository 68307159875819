import { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';

const AutoLogOut = () => {

const [currIp, setCurrIp] = useState(null);
const [userLog, setUserLog] = useState(null);
const [userip, setUserIp] = useState(null);

//console.log(currIp);
//console.log(userip);
//console.log(userLog);

var startAuto;
async function fetchIp(){
try {
const getData = await axios.get('https://ipinfo.io/json');
const resData = await getData.data;
if(resData !== undefined){
setCurrIp(resData.ip);   
}
} catch (error) {
console.log('backend fetch_ip_api error : ' + error);
}
}

async function fetchUser(){
try {
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;    
if(resData.length > 0){
setUserLog(true);
setUserIp(resData[0].ip);
startAuto = setTimeout(fetchUser, 5000);
}
} catch (error) {
clearTimeout(startAuto);
setTimeout(() => {
startAuto = setTimeout(fetchUser, 5000);
}, 1000);
console.log('backend fetch_userData_ip error : ' + error);
}
}

fetchIp();
fetchUser();

useEffect(() => {
if(userLog !== null){
if(currIp !== null && userip !== null){
if(currIp.trim() !== userip.trim()){
//console.log(true);
async function logOutA() {
const getData = await axios.get('/server2/logout');
const resData = await getData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('ip changed. auto logout success');
setTimeout(() => {
$('#msgAlert').hide();
window.location.replace('/');
},2000);
}
}

logOutA();
}
else{
//console.log(false);   
}
}
}
},[userLog, userip, currIp]);

return null;
}

export default AutoLogOut;