import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import AddCoupon from '../../../modals/AddCoupon';
import CouponStatus from '../../../modals/CouponStatus';
import RemoveCoupon from '../../../modals/RemoveCoupon';
import { GlobalContext } from '../../../context/AppContext';

const Coupon = () => {

const { coupon } = GlobalContext();
const [currCoupon, setCurrCoupon] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currCoupon.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currCoupon.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const couponStatus = (id,sta) => {
$('#statusCouModal').show();
$('#statusCou').text(sta);
$('#statusCouId').val(id);
}

const delCoupon = (id) => {
$('#removeCouModal').show();
$('#removeCouId').val(id);
}

const addCoupon = () => {
$('#addCouponModal').show();
}

useEffect(() => {
if(coupon.length > 0){
setCurrCoupon(coupon);
}
},[coupon]);

return (
<>
<div className='paymentContents'>
<div className='rightBtn_pay'>
<button onClick={addCoupon}><i className='fa fa-plus' /> add coupon</button>
</div>

<div className='paymentDataDiv'>
<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currCoupon.length} records...</span>
</div>

<div className='table-responsive'>
<table className='paymentTable'>
<thead>
<tr>
<th>coupon name</th>
<th>coupon code</th>
<th>min. deposit</th>
<th>bonus</th>
<th>start date</th>
<th>end date</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.couponName}</td>
<td>{data.couponCode}</td>
<td>{data.minDepo}</td>
<td>{data.couponDis}</td>
<td>{new Date(data.dateStart).toLocaleDateString()}</td>
<td>{new Date(data.dateEnd).toLocaleDateString()}</td>
<td>
{
data.status === 'active' ? 
<span className="statusTd actGreen">{data.status}</span> :
<span className="statusTd actRed">{data.status}</span>
}
</td>
<td>
<button className='tdBtn' onClick={() => couponStatus(data.id,data.status)}><i className='fa fa-cog' /></button>
<button className='tdBtn' onClick={() => delCoupon(data.id)}><i className='fa fa-trash' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>

<AddCoupon />
<CouponStatus />
<RemoveCoupon />
</>
)
}

export default Coupon;
