import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import PageSideMenu from '../extra/PageSideMenu';
import { GlobalContext } from '../context/AppContext';

const Statement = () => {

const location = useLocation();
const { userBank, allUsers, coupon } = GlobalContext();
const [usedCou, setUsedCou] = useState([]);
const [currStatement, setCurrStatement] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currStatement.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currStatement.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const closeViewUse = () => {
$('#couponShDiv').hide();   
}

const openViewUse = () => {
$('#couponShDiv').css({display: 'flex'}); 
}

useEffect(() => {
if(userBank.length > 0){
var start_date = Date.parse(new Date(startDate).toDateString());
var end_date = Date.parse(new Date(endDate).toDateString());
setCurrStatement(userBank.filter((e) => e.receiver.trim() === (location.state !== null ? location.state.name : null) && e.date_parse >= start_date && e.date_parse <= end_date));
}
},[userBank, location.state, startDate, endDate]);

useEffect(() => {
var useCou = [];
if(allUsers.length > 0){
const findUsr = allUsers.filter((e) => e.customer === location.state.name);
//console.log(findUsr);
for(let i = 0; i < findUsr.length; i++){
for(let j = 0; j < coupon.length; j++){
if(findUsr[i].applycode === coupon[j].couponCode && findUsr[i].applyStatus === 'applied'){
var data = {
couName: coupon[j].couponName,
couCode: coupon[j].couponCode,
couMin: coupon[j].minDepo,
couBon: coupon[j].couponDis
}

const findMatch = useCou.findIndex((o) => o.couCode === coupon[j].couponCode);
if(findMatch === -1){
useCou.push(data); 
}
}
}
}
}
setUsedCou(useCou);
},[allUsers, coupon, location]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu />
</div>
<div className='col'>

<div className='dateDivWrap'>
<div className='dateForm'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile headPrfFlex'>
<div><span>account statement</span></div>
{
usedCou.length > 0 &&
<div className='marketCol'><span className='useCou' onClick={openViewUse}>coupon used</span></div>
}
</div>

<div className='couponShDiv' id='couponShDiv'>
<div className='content_cou'>
<i className='fa fa-close' onClick={closeViewUse} />
{
usedCou.length > 0 ? 
<div className='couponDes'>
<span className='cou1'>{usedCou[0].couName}</span>
<span className='cou2'>min deposit : {usedCou[0].couMin}</span>
<div className='couCodeo'>
<span>{usedCou[0].couCode}</span>
<span>coupon bonus ₹{usedCou[0].couBon}</span>
</div>
</div>
:
<div className='expiredDi'>
<img src='/images/alertExp.png' alt='alertExp' />
<span>coupon has been expired.</span>
</div>
}
</div>
</div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currStatement.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>date/time</th>
<th>deposit</th>
<th>withdrawl</th>
<th>balance</th>
<th>new bal.</th>
<th>remark</th>
<th>from/to</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.create_date}</td>
<td>{data.deposit !== null ? parseFloat(data.deposit.toFixed(2)).toLocaleString() : 0}</td>
<td>{data.withdrawl !== null ? parseFloat(data.withdrawl.toFixed(2)).toLocaleString() : 0}</td>
<td>{parseFloat(data.curr_bal.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.new_bal.toFixed(2)).toLocaleString()}</td>
<td>{data.remark}</td>
<td>{data.sender} <i className='fa fa-arrow-right'></i> {data.receiver}</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</>
)
}

export default Statement;
