import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalContext } from '../context/AppContext';

const viewCoupon = '/server2/view_coupon';

const AddCoupon = () => {

const { GetCoupon, userActive } = GlobalContext();
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

const closeAddCoupon = () => {
$('#addCouponModal').hide();
$('.coup').val('');
}

const ChangeMinDepo = (val) => {
if(val <= 0){
$('#couponMinDepo').val(0);   
}
}

const ChangeCouDis = (val) => {
if(val <= 0){
$('#couponDis').val(0);   
}  
}

const addCouponData = async() =>{
const couponName = $('#couponName').val().trim().toLowerCase();
const couponCode = $('#couponCode').val().trim().toLowerCase();
const minDepo = $('#couponMinDepo').val().trim();
const couponDis = $('#couponDis').val().trim();
const start_date = Date.parse(new Date(startDate).toDateString());
const end_date = Date.parse(new Date(endDate).toLocaleString());
const dateStart = new Date(startDate).toDateString();
const dateEnd = new Date(endDate).toDateString();
const creatorRole = userActive.length > 0 && userActive[0].user_role;

if(couponName === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('coupon name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couponName.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('coupon name min 6 length required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couponCode === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('coupon code required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couponCode.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('coupon code min 6 length required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(minDepo.length <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min deposit 1 required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(couponDis.length <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('coupon bonus min 1 required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/add_coupon', {
couponName: couponName,
couponCode: couponCode,
minDepo: minDepo,
couponDis: couponDis,
start_date: start_date,
end_date: end_date,
dateStart: dateStart,
dateEnd: dateEnd,
creatorRole: creatorRole
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('coupon created successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#addCouponModal').hide();
$('.coup').val('');
GetCoupon(viewCoupon);
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend add_coupon error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='addCouponModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>add coupon details</span> <i className='fa fa-close right' onClick={() => closeAddCoupon()}></i></div>
<div className='modalsSm_body'>

<div className='paymentForm'>
<div className='paymentInput'>
<label>coupon name *</label>
<input type='text' className='coup' name='couponName' id='couponName' placeholder='coupon name...' />
</div>

<div className='paymentInput'>
<label>coupon code *</label>
<input type='text' className='coup' name='couponCode' id='couponCode' placeholder='coupon code...' />
</div>

<div className='paymentInput'>
<label>minimum deposit *</label>
<input onChange={(e) => ChangeMinDepo(e.target.value)} type='number' className='coup' name='couponMinDepo' id='couponMinDepo' placeholder='minimum deposit...' />
</div>

<div className='paymentInput'>
<label>coupon bonus *</label>
<input onChange={(e) => ChangeCouDis(e.target.value)} type='number' className='coup' name='couponDis' id='couponDis' placeholder='coupon bonus...' />
</div>

<div className='paymentInput'>
<label>start date *</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>

<div className='paymentInput'>
<label>end date *</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>

<div className='paymentUpiBut'>
<button onClick={addCouponData}>add coupon</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default AddCoupon;
