import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const viewGameImg = '/server2/theme_game_image';

const GameImgSet = () => {

const { gameImg, GetGameImg } = GlobalContext();
const [currCricImg, setCurrCricImg] = useState(null);
const [currSocImg, setCurrSocImg] = useState(null);
const [currTenImg, setCurrTenImg] = useState(null);
const [currCasImg, setCurrCasImg] = useState(null);
const [currVirImg, setCurrVirImg] = useState(null);
const [currGamImg, setCurrGamImg] = useState([]);

const updateGameImgTh = async(sport) => {
const formData = new FormData();
const cricImg = currCricImg;
const socImg = currSocImg;
const tennImg = currTenImg;
const casImg = currCasImg;
const virImg = currVirImg;

if(sport === 'cricket' && cricImg === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('cricket image required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
else if(sport === 'soccer' && socImg === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('soccer image required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
else if(sport === 'tennis' && tennImg === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('tennis image required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
else if(sport === 'casino' && casImg === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('casino image required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
else if(sport === 'virtual' && virImg === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('virtual image required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}

try{
if(sport === 'cricket'){
formData.append('gameImg', cricImg);
formData.append('sport', sport);
}
else if(sport === 'soccer'){
formData.append('gameImg', socImg);
formData.append('sport', sport);
}
else if(sport === 'tennis'){
formData.append('gameImg', tennImg);
formData.append('sport', sport);
}
else if(sport === 'casino'){
formData.append('gameImg', casImg);
formData.append('sport', sport);
}
else if(sport === 'virtual'){
formData.append('gameImg', virImg);
formData.append('sport', sport);
}

const sendData = await axios.post('/server2/theme_gameimage_update', formData, {
headers : { 'Content-Type' : 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('update changes saved successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetGameImg(viewGameImg);
if(sport === 'cricket'){
$('#setcricImg').val('');
}
else if(sport === 'soccer'){
$('#setsoccImg').val('');
}
else if(sport === 'tennis'){
$('#settennImg').val('');
}
else if(sport === 'casino'){
$('#setcasImg').val('');
}
else if(sport === 'virtual'){
$('#setVirtImg').val('');
}
},2000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},2000);   
}
} catch (error) {
console.log('backend game_image_theme update error : ' + error);
}
}

const removegamImg = async(id,img) => {
const path = img.replace('/server2', 'public');

try {
const sendData = await axios.post('/server2/remove_game_image', {
id: id,
path: path
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('image removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetGameImg(viewGameImg);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('server remove_theme_game_image error : ' + error);
}
}

useEffect(() => {
if(gameImg.length > 0){
const crcCheck = gameImg.filter((e) => e.sport === 'cricket');
const soccCheck = gameImg.filter((e) => e.sport === 'soccer');
const tennCheck = gameImg.filter((e) => e.sport === 'tennis');
const casCheck = gameImg.filter((e) => e.sport === 'casino');
const virCheck = gameImg.filter((e) => e.sport === 'virtual');

if(crcCheck.length > 0){
$('#cricLock').css({display: 'flex'});
}
else{
$('#cricLock').css({display: 'none'});
}

if(soccCheck.length > 0){
$('#soccLock').css({display: 'flex'});
}
else {
$('#soccLock').css({display: 'none'});
}

if(tennCheck.length > 0){
$('#tennLock').css({display: 'flex'});
}
else {
$('#tennLock').css({display: 'none'});
}

if(casCheck.length > 0){
$('#casLock').css({display: 'flex'});
}
else {
$('#casLock').css({display: 'none'});
}

if(virCheck.length > 0){
$('#virLock').css({display: 'flex'});
}
else {
$('#virLock').css({display: 'none'});
}

setCurrGamImg(gameImg);
}
else{
setCurrGamImg([]);
$('#cricLock').css({display: 'none'});
$('#soccLock').css({display: 'none'});
$('#tennLock').css({display: 'none'});
$('#casLock').css({display: 'none'});
$('#virLock').css({display: 'none'});
}
},[gameImg]);

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>

<div className='profilePage'>
<div className='headerProfile'><span>game image settings</span></div>

<div className='contactSetDiv'>
<div className='contactSetView'>

<div className='conBoWra'>
<div className='conBo'>
<div className='conBo_lock' id='cricLock'><i className='fa fa-lock' /></div>
<div className='contactSetInput'>
<label>cricket image</label>
<input onChange={(e) => setCurrCricImg(e.target.files[0])} type='file' name='setcricImg' id='setcricImg' accept='image/*' />
</div>

<div className='contactSetSubmit'>
<button onClick={() => updateGameImgTh('cricket')}>save changes</button>
</div>
</div>

<div className='conBo'>
<div className='conBo_lock' id='soccLock'><i className='fa fa-lock' /></div>
<div className='contactSetInput'>
<label>soccer image</label>
<input onChange={(e) => setCurrSocImg(e.target.files[0])} type='file' name='setsoccImg' id='setsoccImg' accept='image/*' />
</div>

<div className='contactSetSubmit'>
<button onClick={() => updateGameImgTh('soccer')}>save changes</button>
</div>
</div>

<div className='conBo'>
<div className='conBo_lock' id='tennLock'><i className='fa fa-lock' /></div>
<div className='contactSetInput'>
<label>tennis image</label>
<input onChange={(e) => setCurrTenImg(e.target.files[0])} type='file' name='settennImg' id='settennImg' accept='image/*' />
</div>

<div className='contactSetSubmit'>
<button onClick={() => updateGameImgTh('tennis')}>save changes</button>
</div>
</div>

<div className='conBo'>
<div className='conBo_lock' id='casLock'><i className='fa fa-lock' /></div>
<div className='contactSetInput'>
<label>casino image</label>
<input onChange={(e) => setCurrCasImg(e.target.files[0])} type='file' name='setcasImg' id='setcasImg' accept='image/*' />
</div>

<div className='contactSetSubmit'>
<button onClick={() => updateGameImgTh('casino')}>save changes</button>
</div>
</div>

<div className='conBo'>
<div className='conBo_lock' id='virLock'><i className='fa fa-lock' /></div>
<div className='contactSetInput'>
<label>virtual image</label>
<input onChange={(e) => setCurrVirImg(e.target.files[0])} type='file' name='setVirtImg' id='setVirtImg' />
</div>

<div className='contactSetSubmit'>
<button onClick={() => updateGameImgTh('virtual')}>save changes</button>
</div>
</div>
</div>

</div>
</div>

</div>

<div className='profilePage marTop25'>
<div className='headerProfile'><span>game images</span></div>

<div className='viewSliderWrap'>
<div className='viewSliderDiv'>
<div className='viewSliderRow'>
{
currGamImg.length > 0 ? currGamImg.map((data, index) => {
return (
<div className='viewSliderBox' key={index}>
<img src={data.path} alt='slider-img' />
<div className='viewSlideCon'>
<div className='viewSlide'>
<span>
<a href={data.path} target='blank'><i className='fa fa-eye' /></a>
<i className='fa fa-trash' onClick={() => removegamImg(data.id,data.path)} />
</span>
<span>sport page : {data.sport}</span>
</div>
</div>
</div>
)
})
:
<div className='noreSlide'>
<img src='/images/cloud.png' alt='cloud' />
<span>oop's ! you have no any game image</span>
</div>
}
</div>
</div>
</div>
</div>

</div>
</div>

</div>
</div>
</>
)
}

export default GameImgSet;
